import axios from "@/plugins/axios";
import * as mains from "./mains";
import * as reviews from "./reviews";
import * as reviews2 from "./reviews2";
import * as settings from "./settings";

export const easyleg = {
    ...mains,
    ...reviews,
    ...reviews2,
    ...settings
}